import React from "react";
import styles from "./DateQuestion.module.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const DateQuestion = ({ handleSendAnswer, question }) => {
  const [startDate, setStartDate] = React.useState("Select date");
  const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <button className={styles.DateButton} onClick={onClick} ref={ref}>
      📅 {startDate}
    </button>
  ));
  function convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join("-");
  }
  return (
    <div className={styles.DateQuestion}>
      <DatePicker
        // selected={startDate}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        customInput={<ExampleCustomInput />}
        onChange={(date) => {
          handleSendAnswer(convertDate(date));

          setStartDate(convertDate(date));
        }}
      />
    </div>
  );
};

export default DateQuestion;
