import React from "react";
import styles from "./RadioQuestion.module.css";
const RadioQuestion = ({ handleSendAnswer, question }) => {
  const [selectedValue, setSelectedValue] = React.useState("");
  const onChange = (event) => {
    if (event.target.value === "") return;
    handleSendAnswer(event.target.value);
    setSelectedValue(event.target.value);
  };

  return (
    <div className={styles.RadioQuestion} onChange={onChange}>
      {question &&
        question.radioOptions &&
        question.radioOptions?.map((option) => (
          <div key={option.value} className={styles.SingleRadio}>
            <input type="radio" value={option.value} name={question.name} />
            <p>{option.text}</p>
          </div>
        ))}
      {/* <input type="text" disabled placeholder="Please select an option above" /> */}
    </div>
  );
};

export default RadioQuestion;
