import React from "react";
import styles from "./PhotoQuestion.module.css";
import Webcam from "react-webcam";
import LoadingOverlay from "react-loading-overlay";
const PhotoQuestion = ({ handleSendAnswer, question }) => {
  const [selectedValue, setSelectedValue] = React.useState("");
  const [showCamera, setShowCamera] = React.useState(false);
  const webcamRef = React.useRef(null);
  const [laodingCamera, setLoadingCamera] = React.useState(false);
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    handleSendAnswer(imageSrc);
  }, [webcamRef]);

  const videoConstraints = {
    width: 320,
    height: 256,
    facingMode: "user",
  };
  const onChange = (event) => {
    if (event.target.value === "") return;
    setSelectedValue(event.target.value.trim());
  };
  const onUserMedia = () => {
    console.log("onUserMedia called!");
    setLoadingCamera(false);
  };
  const onUserMediaError = () => {
    alert(
      "Unable to access camera, please check camera permissions in browser settings."
    );
  };
  const handleStartCameraClick = () => {
    setShowCamera(true);
    setLoadingCamera(true);
    const chatWindow = document.getElementById("chat-window");
    var xH = chatWindow.scrollHeight;
    chatWindow.scrollTo(0, xH);
  };

  return (
    <div className={styles.PhotoQuestion} onChange={onChange}>
      <div>
        <button onClick={handleStartCameraClick} className={styles.StartButton}>
          Start Camera
        </button>
      </div>

      {showCamera && (
        <div className={styles.WebcamBox}>
          {laodingCamera && (
            <LoadingOverlay
              active={laodingCamera}
              spinner
              text="detecting camera..."
            ></LoadingOverlay>
          )}
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            onUserMedia={onUserMedia}
            onUserMediaError={onUserMediaError}
          />

          <div>
            <img src={"/camera.svg"} alt="camera" />
            <button onClick={capture}>Capture photo</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PhotoQuestion;
