import React from "react";
import styles from "./ProfileData.module.css";

import LoadingOverlay from "react-loading-overlay";
const ProfileData = ({ profileData }) => {
  const [loading, setLoading] = React.useState(false);
  return (
    <>
      <div className={styles.ProfileData}>
        <div>
          {profileData ? (
            <div className={styles.ProfileDataBox}>
              <div>
                <h3>Aadhaar Number:</h3>
                <p>{profileData.aadhaar_id}</p>
              </div>
              <div>
                <h3>Address:</h3>
                <p>{profileData.address}</p>
              </div>
              <div>
                <h3>Date Of Birth:</h3>
                <p>{profileData.dob}</p>
              </div>
              <div>
                <h3>Name</h3>
                <p>{profileData.name}</p>
              </div>
              <div>
                <h3>Email:</h3>
                <p>{profileData.email}</p>
              </div>
              <div>
                <h3>Face Verified:</h3>
                <p>{profileData.face_verified ? "Yes" : "No"}</p>
              </div>
              <div>
                <h3>Verified Image: </h3>
                {profileData.image_data ? (
                  <img
                    src={profileData.image_data}
                    alt="aadhaar face verification"
                  />
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ProfileData;
