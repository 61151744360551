import React, { useState, useEffect } from "react";
import ChatBox from "./ChatBox";
import styles from "./App.module.css";
import toast, { Toaster } from "react-hot-toast";
import ProfileData from "./components/ProfileData/ProfileData";

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}
const App = () => {
  const [profileData, setProfileData] = React.useState(null);
  const setProfile = (profileData) => {
    setProfileData(profileData);
  };
  /**
 2 aadhaar... 4 name...
 2 name... 5 aadhaar...
 
 */
  return (
    <>
      <div className={styles.App}>
        <div className={styles.Container}>
          <ChatBox setProfile={setProfile} profileData={profileData} />
          <ProfileData profileData={profileData} />
        </div>
      </div>
      <Toaster />
    </>
  );
};

export default App;
