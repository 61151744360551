export const questions = [
  {
    name: "email",
    title: "Enter your email:",
    type: "input",
    placeholder: "type email here...",
  },
  {
    name: "password",
    title: "Enter your password:",
    type: "input",
    placeholder: "type password here...",
  },

  {
    name: "name",
    title: "Please enter your name as per your aadhaar id ?",
    type: "input",
    placeholder: "type name here...",
  },
  {
    name: "date_of_birth",
    title: "Please select your date of birth as per your aadhaar id ?",
    type: "date",
  },
  {
    name: "address",
    title: "Please enter your address as per your aadhaar id ?",
    type: "input",
    placeholder: "type address here...",
  },

  {
    name: "aadhaar_number",
    title: "What is your aadhaar number ?",
    type: "input",
    placeholder: "enter aadhaar number here...",

    maxLength: 12,
  },
  {
    name: "verify_otp",
    title: "Enter OTP ?",
    type: "input",
    placeholder: "enter otp here..",
    maxLength: 6,
  },
  {
    name: "take_photo",
    title:
      "Please click the following button to start camera and make sure to allow camera access to browser if asked.",
    type: "photo",
  },
  {
    name: "message",
    title: "Hooray! It's done.",
    type: "info-success",
  },
];
