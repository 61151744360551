import React from "react";
import styles from "./ChatBox.module.css";
import InputQuestion from "./components/InputQuestion/InputQuestion";
import RadioQuestion from "./components/RadioQuestion/RadioQuestion";
import DateQuestion from "./components/DateQuestion/DateQuestion";
import toast, { Toaster } from "react-hot-toast";
import PhotoQuestion from "./components/PhotoQuestion/PhotoQuestion";
import LoadingOverlay from "react-loading-overlay";
import axios from "axios";
import { questions } from "./questions";
import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";

const baseUrl = "https://b497-182-77-115-132.ngrok.io";

const ChatBox = ({ setProfile, profileData }) => {
  //1. states
  const [chatHistory, setChatHistory] = React.useState(
    localStorage.getItem("chatHistory") === null
      ? []
      : JSON.parse(localStorage.getItem("chatHistory"))
  );

  const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [isOtpVerified, setIsOtpVerified] = React.useState(
    localStorage.getItem("isOtpVerified") ? true : false
  );
  const [isImageVerified, setIsImageVerified] = React.useState(
    localStorage.getItem("isImageVerified") ? true : false
  );
  const [isBasicInfoVerified, setIsBasicInfoVerified] = React.useState(
    localStorage.getItem("isBasicInfoVerified") ? true : false
  );
  const [isLoggedIn, setIsLoggedIn] = React.useState(
    localStorage.getItem("token") !== null ? true : false
  );
  const [stagePercentage, setStagePercentage] = React.useState(0);
  //2. use effects
  React.useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      // if loggedin
      hanldleGetProfile();
    }
  }, []);
  React.useEffect(() => {
    let chatHistory = localStorage.getItem("chatHistory");
    if (chatHistory && chatHistory.length > 0) {
      chatHistory = JSON.parse(chatHistory);
      if (!(chatHistory && chatHistory.length > 0)) return;

      const lastQuestionName = chatHistory[chatHistory.length - 1].name;
      let lastQuestionIndex = questions.findIndex(
        (ques) => ques.name === lastQuestionName
      );
      if (lastQuestionIndex !== -1) {
        setCurrentQuestionIndex(lastQuestionIndex + 1);
      } else {
        setCurrentQuestionIndex(0);
      }
    }
  }, [isLoggedIn]);

  React.useEffect(() => {
    if (isOtpVerified) setCurrentQuestionIndex(7);
  }, [isOtpVerified]);

  React.useEffect(() => {
    if (isBasicInfoVerified) setCurrentQuestionIndex(5);
  }, [isBasicInfoVerified]);
  React.useEffect(() => {
    if (isImageVerified) {
      setCurrentQuestionIndex(questions.length - 1);
    }
  }, [isImageVerified]);
  React.useEffect(() => {
    const chatWindow = document.getElementById("chat-window");
    var xH = chatWindow.scrollHeight;
    chatWindow.scrollTo(0, xH);
  }, [chatHistory]);

  React.useEffect(() => {}, [currentQuestionIndex]);

  React.useEffect(() => {
    setStagePercentageState();
  }, [profileData]);
  //3. Send answer
  const handleSendAnswer = (answer) => {
    const newChatHistory = [
      ...chatHistory,
      {
        type: "receive",
        message: questions[currentQuestionIndex].title,
      },
      {
        type: "send",
        message: answer,
        name: questions[currentQuestionIndex].name,
      },
    ];

    if (questions[currentQuestionIndex].name === "password") {
      const email = chatHistory
        .find((chat) => chat.name === "email")
        .message.trim();
      const password = answer.trim();
      handleSignIn(email, password, newChatHistory);
    } else if (questions[currentQuestionIndex].name === "aadhaar_number") {
      // send otp
      const aadhaar_number = answer.trim();
      handleSendOTP(aadhaar_number, newChatHistory);
    } else if (questions[currentQuestionIndex].name === "verify_otp") {
      // send otp
      const otp = answer.trim();
      handleVerifyOTP(otp, newChatHistory);
    } else if (questions[currentQuestionIndex].name === "take_photo") {
      // send otp
      const dataImage = answer.trim();
      handleImageVerification(dataImage, newChatHistory);
    } else if (questions[currentQuestionIndex].name === "address") {
      handleBasicInfoUpdate(newChatHistory);
    } else {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setChatHistory(newChatHistory);
      localStorage.setItem("chatHistory", JSON.stringify(newChatHistory));
    }
  };
  // 4. Render UI methods
  const renderCorrectQuestion = (question) => {
    switch (question.type) {
      case "input":
        return (
          <InputQuestion
            handleSendAnswer={handleSendAnswer}
            question={question}
          />
        );
      case "radio":
        return (
          <RadioQuestion
            handleSendAnswer={handleSendAnswer}
            question={question}
          />
        );
      case "date":
        return (
          <DateQuestion
            handleSendAnswer={handleSendAnswer}
            question={question}
          />
        );
      case "photo":
        return (
          <PhotoQuestion
            handleSendAnswer={handleSendAnswer}
            question={question}
          />
        );
      default:
        return null;
    }
  };
  const renderCurrentQuestion = (question) => {
    if (currentQuestionIndex >= questions.length) return null;
    const currentQuestion = questions[currentQuestionIndex];
    return (
      <div className={styles.CurrentQuestion + " " + styles.receive}>
        <p
          style={
            currentQuestion && currentQuestion.type === "info-success"
              ? { color: "#198754" }
              : null
          }
        >
          {currentQuestion.title}
        </p>
        {renderCorrectQuestion(currentQuestion)}
      </div>
    );
  };

  const renderChatHistory = () => {
    return chatHistory.map((chat, i) => (
      <div className={styles[chat.type]} key={i}>
        {chat.name === "take_photo" ? (
          <img src={chat.message} alt="verification" />
        ) : (
          <p>
            {chat.type === "send" && chat.name.includes("password")
              ? "*".repeat(chat.message.length)
              : chat.message}
          </p>
        )}
      </div>
    ));
  };
  //5. Apis: 7 calls
  const handleSignIn = (email, password, newChatHistory) => {
    // const url = "https://idverification.masaischool.com/api/login";
    const url = `${baseUrl}/login`;
    setLoading(true);
    axios
      .post(url, { email, password })
      .then((response) => {
        localStorage.setItem("userId", response.data.data.id);
        localStorage.setItem("token", response.data.data.token);
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setChatHistory(newChatHistory);
        localStorage.setItem("chatHistory", JSON.stringify(newChatHistory));
        setIsLoggedIn(true);
        toast.success("Sign In Successful!");
        hanldleGetProfile();
      })
      .catch((error) => {
        setChatHistory([]);
        localStorage.setItem("chatHistory", JSON.stringify([]));
        setCurrentQuestionIndex(0);
        toast.error("Error while signin in. Please try again!");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleSendOTP = (aadhaarNumber, newChatHistory) => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    const url = `${baseUrl}/profile/${userId}/send-aadhaar-otp`;
    setLoading(true);
    axios
      .post(url, null, {
        params: {
          aadhaar_id: aadhaarNumber,
          token,
        },
      })
      .then((response) => {
        hanldleGetProfile();
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setChatHistory(newChatHistory);
        toast.success("OTP sent successfully!");
        localStorage.setItem("chatHistory", JSON.stringify(newChatHistory));
      })
      .catch((error) => {
        setCurrentQuestionIndex(5);
        toast.error("Error while sending OTP. Please try again!");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleVerifyOTP = (otp, newChatHistory) => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    const url = `${baseUrl}/profile/${userId}/verify-otp`;
    setLoading(true);
    axios
      .post(url, null, {
        params: {
          otp_input: otp,
          token: token,
        }, //
      })
      .then((response) => {
        hanldleGetProfile();
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setChatHistory(newChatHistory);
        localStorage.setItem("chatHistory", JSON.stringify(newChatHistory));
        toast.success("OTP verified successfully!");
        localStorage.setItem("isOtpVerified", JSON.stringify(true));
        setIsOtpVerified(true);
      })
      .catch((error) => {
        setCurrentQuestionIndex(5);
        toast.error("Error while verifying OTP. Please try again!");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleImageVerification = (dataImage, newChatHistory) => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    const url = `${baseUrl}/profile/${userId}/upload-selfie`;
    setLoading(true);
    axios
      .post(
        url,
        { image_data: dataImage },
        {
          params: {
            token,
          },
        }
      )
      .then((response) => {
        hanldleGetProfile();
        setCurrentQuestionIndex(questions.length - 1);
        setChatHistory(newChatHistory);
        localStorage.setItem("chatHistory", JSON.stringify(newChatHistory));
        toast.success("Image verified Successfully!");
        setIsImageVerified(true);
        localStorage.setItem("isImageVerified", JSON.stringify(true));
      })
      .catch((error) => {
        toast.error("Error while verifying Image. Please try again!");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleBasicInfoUpdate = (newChatHistory) => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    const url = `${baseUrl}/profile/${userId}`;
    setLoading(true);
    const name = newChatHistory
      .find((chat) => chat.name === "name")
      .message.trim();
    const dob = newChatHistory
      .find((chat) => chat.name === "date_of_birth")
      .message.trim();
    const address = newChatHistory
      .find((chat) => chat.name === "address")
      .message.trim();

    axios
      .patch(
        url,
        { name, dob, address },
        {
          params: {
            token,
          },
        }
      )
      .then((response) => {
        hanldleGetProfile();
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setChatHistory(newChatHistory);
        localStorage.setItem("chatHistory", JSON.stringify(newChatHistory));
        setIsBasicInfoVerified(true);
        localStorage.setItem("isBasicInfoVerified", JSON.stringify(true));
        toast.success("Information Received Successfully");
      })
      .catch((error) => {
        setCurrentQuestionIndex(2);
        toast.error("Error while updating Information. Please try again!");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const hanldleGetProfile = () => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    const url = `${baseUrl}/profile/${userId}`;
    setLoading(true);
    axios
      .get(url, {
        params: {
          token,
        },
      })
      .then((response) => {
        setProfile(response.data.data);
        toast.success("User profile fetch successfully!");
      })
      .catch((error) => {
        toast.error("Error while getting profile!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSignOut = () => {
    localStorage.clear();
    setStagePercentage(0);
    setIsLoggedIn(false);
    setCurrentQuestionIndex(0);
    setChatHistory([]);
    setIsImageVerified(null);
    setIsOtpVerified(null);
    setIsBasicInfoVerified(null);
  };

  const handleResetChat = () => {
    if (!isLoggedIn) {
      setCurrentQuestionIndex(0);
      localStorage.clear();
      setChatHistory([]);
      setIsImageVerified(null);
      setIsOtpVerified(null);
      setIsBasicInfoVerified(null);
      return;
    }
    setCurrentQuestionIndex(2);
    setChatHistory([]);
    setIsImageVerified(null);
    setIsOtpVerified(null);
    setIsBasicInfoVerified(null);
    localStorage.removeItem("chatHistory");
    localStorage.removeItem("isOtpVerified");
    localStorage.removeItem("isImageVerified");
    localStorage.removeItem("isBasicInfoVerified");
  };
  const setStagePercentageState = () => {
    if (!profileData) return 0;
    switch (profileData.stage_data) {
      case "LOGGED_IN":
        return setStagePercentage(20);
      case "PROFILE_FILLED":
        return setStagePercentage(40);
      case "AADHAAR_OTP_SENT":
        return setStagePercentage(60);
      case "AADHAAR_OTP_VERIFIED":
        return setStagePercentage(80);
      case "AADHAAR_DETAILS_VERIFIED":
        return setStagePercentage(100);

      default:
        return 0;
    }
  };

  //6. JSX
  return (
    <>
      <div className={styles.ChatBox}>
        <div className={styles.ProgressBar}>
          {profileData &&
          profileData.stage_data === "AADHAAR_DETAILS_VERIFIED" ? (
            <h2 style={{ color: "#198754" }}>
              Congratulations! 🎉 You have completed the Masai Verification
              process!
            </h2>
          ) : (
            <h4>
              Last Completed Stage:
              {profileData ? "  " + profileData.stage_data : " None"}
            </h4>
          )}

          <ProgressBar
            percent={stagePercentage}
            filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
          />
        </div>

        {!isImageVerified && (
          <div className={styles.buttons}>
            <button
              disabled={isImageVerified}
              className={styles.SignOut}
              onClick={handleSignOut}
            >
              Reset Chat
            </button>
          </div>
        )}

        <div id="chat-window" className={styles.ChatBox__messages}>
          <div className={styles.ChatHistory}>{renderChatHistory()}</div>
          {renderCurrentQuestion()}
        </div>
        <LoadingOverlay
          active={loading}
          spinner
          text="Please wait..."
        ></LoadingOverlay>
      </div>
    </>
  );
};

export default ChatBox;
