import React from "react";
import styles from "./InputQuestion.module.css";
import toast, { Toaster } from "react-hot-toast";
const InputQuestion = ({ handleSendAnswer, question }) => {
  const [message, setMessage] = React.useState("");

  const handleEnter = (e) => {
    if (e.key !== "Enter") return;

    if (question.name === "aadhaar_number") {
      const aadhaarRegexp = /^([0-9]){12}?$/;
      if (!aadhaarRegexp.test(message)) {
        toast.error("Please enter valid 12 digit aadhaar number.");
        return;
      }
    }
    if (question.name === "verify_otp") {
      const aadhaarRegexp = /^([0-9]){6}?$/;
      if (!aadhaarRegexp.test(message)) {
        toast.error("Please enter 6 digit OTP.");
        return;
      }
    }
    handleSendAnswer(message);
    setMessage("");
  };
  return (
    <div className={styles.InputQuestion}>
      <input
        type={question.name.includes("password") ? "password" : "text"}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyPress={handleEnter}
        placeholder={question.placeholder}
        maxLength={question.maxLength || null}
      />
    </div>
  );
};

export default InputQuestion;
